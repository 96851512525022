<template>
  <div>
    <div class="mb-20">
      <el-button
        :type="onlyDiscarded ? 'default' : 'info'"
        size="medium"
        @click="onlyDiscarded = false"
        round>
        Inbox
      </el-button>
      <el-button
        :type="onlyDiscarded ? 'info' : 'default'"
        size="medium"
        @click="onlyDiscarded = true"
        round>
        Discarded
      </el-button>
    </div>

    <el-empty
      v-if="!messages.length"
      :image-size="50"
      description="There are no system messages." />

    <panel v-for="message in messages" :key="message.id">
      <message-item
        :message="message"
        @discard="discard(message.id)" />
    </panel>
  </div>
</template>

<script>
import MessageItem from '@/components/other/MessageItem'

export default {
  components: {
    MessageItem
  },

  data () {
    return {
      onlyDiscarded: false
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    read () {
      const data = this.$store.state.app.readed
      return data.filter(item => item.itemType === 'SYSTEM_MESSAGES')
    },

    discarded () {
      return this.$store.state.app.discarded.filter(item => item.itemType === 'SYSTEM_MESSAGES')
    },

    messages () {
      const messages = this.$store.state.app.systemMessage
      return messages.slice().sort((a, b) => {
        const aa = parseInt(a.id)
        const bb = parseInt(b.id)
        if (aa > bb) return -1
        if (aa < bb) return 1
        return 0
      }).map(message => {
        return {
          ...message,
          read: this.read.some(read => parseInt(read.itemId) === parseInt(message.id)),
          discarded: this.discarded.some(discarded => parseInt(discarded.itemId) === parseInt(message.id))
        }
      }).filter(message => !message.archived && message.discarded === this.onlyDiscarded)
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Messages',
      back: { name: 'Home' }
    })
  },

  methods: {
    async discard (id) {
      const data = await this.$store.dispatch('discarded/create', {
        itemType: 'SYSTEM_MESSAGES',
        itemId: id,
        user: this.user.id
      })
      this.$store.commit('app/addDiscarded', data)
    }
  }
}
</script>
